import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Component, OnInit, ViewEncapsulation, ViewChild, ElementRef } from "@angular/core";
import { DomSanitizer, SafeHtml } from "@angular/platform-browser";
import { ApiService } from "src/app/services/api.service";
import { ScriptsService } from "src/app/services/scripts.service";
import { environment } from "src/environments/environment";
import { SurveyValidation } from "../models/surveyData.model";
import { ExcelExportService } from "../services/excel-export.service";
import { FormValidationService } from "../services/form-validation.service";
import { PdfExportService } from "../services/pdf-export.service";
import { msaHasTilde } from "../common/shared";
import { ActivatedRoute } from "@angular/router";
import { checkEnv, getAPIURL, getContentURL } from 'src/app/util/config';

@Component({
  selector: "app-five-year-estimate",
  templateUrl: "./five-year-estimate.component.html",
  styleUrls: ["./five-year-estimate.component.scss"],
  encapsulation: ViewEncapsulation.None,
})
export class FiveYearEstimateComponent implements OnInit {
  statedata: any[];
  msadata: any[];
  filteredMsa: any[];
  selectedState: string = "";
  selectedMSA: string = "";
  selectedYear: string = "2009-2013";
  selectedArea: string = "National";
  fileName: string;
  title: string;
  public externalHtml: SafeHtml;
  //serverAppendUrl = getContentURL();
  // Updated contentUrl to S3 Bucket Url.
  serverAppendUrl = getAPIURL(); 
  loading = false;
  placeData = false;
  fileNotFound = false;

  footnote: string = "";
  msanote: string = "";

  @ViewChild("content", { static: false }) content: ElementRef;

  constructor(
    private http: HttpClient,
    private sanitizer: DomSanitizer,
    private apiService: ApiService,
    private scripts: ScriptsService,
    private formValidationService: FormValidationService,
    private excelExportService: ExcelExportService,
    private pdfExportService: PdfExportService,
    private route: ActivatedRoute
  ) {
    this.initSetup();
  }

  initSetup(): void {
    const { type, geo, year } = this.route.snapshot.queryParams;
    this.apiService.getStateNames().then((states: any) => {
      this.statedata = states;
      if (type && year) {
        this.placeData = true;
        if ("state" === type) {
          this.selectedArea = "State";
          let stateCode = this.statedata.find(
            (o) => o.FullText.toLowerCase() === geo.toLowerCase()
          );
          this.selectedState = stateCode.Value;
        } else if ("msa" === type) {
          this.selectedArea = "MSA";
          this.selectedMSA = geo;
        }
        this.selectedYear = year;
        this.getEstimateHtml();
      } else {
        this.apiService.getFiveYearMsas().then((msas: any) => {
          this.msadata = msas;
          this.filteredMsa = this.msadata;
        });
      }
    });
  }

  ngOnInit(): void {
    this.scripts.loadHeaderFooterScript();
  }

  getEstimateHtml() {
    if (false === this.validate()) {
      return;
    }
    this.loading = true;

    this.title = "";
    const headers = new HttpHeaders({
      "Content-Type": "text/plain",
    });

    let filename = this.getFileName();
    //let contentURL = this.serverAppendUrl + `/analysis/household-survey/five-year/documents/${filename}.html`;// will not work for localhost
    let contentURL = "https://cg-7ce3a684-2bed-464c-8d1c-1a0e4cba69c6.s3.us-gov-west-1.amazonaws.com" + `/analysis/household-survey/five-year/documents/${filename}.html`;
    // Updated contentUrl to S3 Bucket Url.
    if (checkEnv() === 'prod') {
      const requestProd = this.http
        .get(
          contentURL,
          {
            headers: headers,
            responseType: "text",
          }
        )
        .subscribe(
          (res) => {
            const index = res.lastIndexOf("<table");
            const indexbody = res.indexOf("</table>");
            this.getTitle();
            this.externalHtml = this.sanitizer.bypassSecurityTrustHtml(
              res.slice(index, indexbody)
            );
            this.loading = false;
          },
          (error) => {
            this.externalHtml = "<h3>No Data Found</h3>";
            this.loading = false;
            this.fileNotFound = true;
          }
      );
    } else { //withCredentials: true only implemented for lower environments, not needed for prod

      const request = this.http
        .get(
          contentURL,
          {
            headers: headers,
            responseType: "text",
            withCredentials: true,
          }
        )
        .subscribe(
          (res) => {
            const index = res.lastIndexOf("<table");
            const indexbody = res.indexOf("</table>");
            this.getTitle();
            this.externalHtml = this.sanitizer.bypassSecurityTrustHtml(
              res.slice(index, indexbody)
            );
            this.loading = false;
          },
          (error) => {
            this.externalHtml = "<h3>No Data Found</h3>";
            this.loading = false;
            this.fileNotFound = true;
          }
        );
    }

    this.addFootnotes();
  }

  clearAll() {
    this.externalHtml = "";
    this.title = "";
    this.fileName = "";
    this.selectedYear = "2009-2013";
    this.selectedArea = "National";
    this.selectedState = "";
    this.selectedMSA = "";
  }

  reset() {
    this.externalHtml = "";
    this.fileName = "";
    this.title = "";
    this.selectedArea = "National";
    this.selectedState = "";
    this.selectedMSA = "";
  }

  validate(): boolean {
    if (this.placeData) {
      return;
    }
    if (this.selectedArea === "State" || this.selectedArea === "MSA") {
      const stateNumber =
        this.selectedState === "" ? 0 : parseInt(this.selectedState);
      const msaNumber =
        this.selectedMSA === "" ? 0 : parseInt(this.selectedMSA);
      const validationFields =
        this.selectedArea === "State"
          ? ["five-year-state"]
          : ["five-year-state", "five-year-msa"];
      const validationObj: SurveyValidation = {
        validationFields,
        area: this.selectedArea,
        state: stateNumber,
        msa: msaNumber,
      };
      return this.formValidationService.startValidation(validationObj);
    }
    return true;
  }

  getFileName() {
    if ("MSA" === this.selectedArea) {
      let str = this.selectedMSA;
      // str = str.replace(/[,|~|.]/g, "").replace(/\s/g, "-");
      str = str.replace(/[,|~|.]/g, "").replace(/[\s|_|/]/g, "-");

      this.fileName =
        this.selectedYear +
        "-" +
        this.selectedArea.toLowerCase() +
        "-" +
        str.toLowerCase();
    } else if ("State" === this.selectedArea) {
      this.fileName =
        this.selectedYear + "-" + this.selectedState.toLowerCase();
    } else {
      this.fileName = this.selectedYear + "-" + this.selectedArea;
    }
    return this.fileName;
  }

  getTitle() {
    this.title = "Five-Year Estimates for ";
    if ("MSA" === this.selectedArea) {
      this.title += this.selectedYear + ", " + this.selectedMSA;
    } else if ("State" === this.selectedArea) {
      let statedesc = this.statedata.find(
        (o) => o.Value === this.selectedState
      );
      this.title += this.selectedYear + ", " + statedesc.FullText;
    } else {
      this.title += this.selectedYear + ", " + this.selectedArea;
    }
  }

  filtermsa() {
    this.msanote = "";
    if (this.selectedState !== "") {
      this.filteredMsa = this.msadata.filter((item) => {
        return (
          item.MsaSelect.toString().indexOf(this.selectedState) > -1 &&
          item.YearRange === this.selectedYear
        );
      });
    } else {
      this.filteredMsa = this.msadata;
    }
  }

  addFootnotes() {
    if (this.selectedYear.includes("2009-2013")) {
      this.footnote =
        "For the 2009-2013 five-year estimates, racial and ethnic categories are described in Appendix 1 (FDIC Technical Notes) of the 2017 report.";
    } else if (this.selectedYear.includes("2011-2015")) {
      this.footnote =
        "For the 2011-2015 five-year estimates, racial and ethnic categories are described in Appendix 1 (FDIC Technical Notes) of the 2019 report.";
    } else if (this.selectedYear.includes("2013-2017")) {
      this.footnote =
        "For the 2013-2017 five-year estimates, racial and ethnic categories are described in Appendix 1 (FDIC Technical Notes) of the 2019 report.";
    } else if (this.selectedYear.includes("2015-2019")) {
      this.footnote =
        "For the 2015-2019 five-year estimates, racial and ethnic categories are described in Appendix 1 (FDIC Technical Notes) of the 2019 report.";
    } else if (this.selectedYear.includes("2017-2021")) {
      this.footnote =
        "For the 2017-2021 five-year estimates, racial and ethnic categories are described in Appendix 1 (FDIC Technical Notes) of the 2019 report.";
    }
  }

  checkForTilde() {
    this.msanote = msaHasTilde("value", this.selectedMSA, this.filteredMsa);
  }

  exportexcel(): void {
    // Identify the file name to use
    let excelFileName = this.fileName + ".csv";
    // Get the table id to export
    let element = document.getElementsByTagName("table")[0];
    let headnote = '"' + this.title + '"\n\n';
    let footnotes = document.getElementById("footnotes").querySelectorAll("li");

    // Call Excel Service
    this.excelExportService.exportToExcel(
      excelFileName,
      element,
      headnote,
      footnotes
    );
  }

  public exportpdf() {
    let element = document.getElementsByTagName("table")[0];
    let footnotes = document.getElementById("footnotes");

    let pdfParams = {
      fileName: this.fileName,
      dataTable: element,
      title: this.title,
      footNote: footnotes,
    };
    this.pdfExportService.downloadPDF(pdfParams);
  }

  export(event) {
    if ("downloadcsv" === event.srcElement.classList[1]) {
      this.exportexcel();
    } else if ("downloadpdf" === event.srcElement.classList[1]) {
      this.exportpdf();
    }
  }
}
